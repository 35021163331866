import { defineStore } from 'pinia'
import axios from "axios"

export const dhRegistrationStore = defineStore("dh-registration", {
    state: () => ({
        subject: {
            age: null,
            sex: "",
            firstName: "",
            lastName: "",
            dateOfBirth: "",
            phone: "",
            email: "",
            socialSecurity: "",
            signature: "",
            password: "",
            height: null,
            bodyweight: null,
            cohort: null,
            address: {
                street: "",
                streetNr: "",
                blockNr: "",
                doorNr: "",
                ZIP: "",
                city: "",
                country: "Österreich"
            }
        },
        eligibility: {
            afib: null,
            anticoagulant: "",
            anticoagulantBool: null
        },
        risk: {
            angiopathy: "",
            angiopathyConfirmed: null,
            pacemaker: null,
            diabetes: null,
            myocardialInfarction: null,
            hypertension: null,
            heartFailure: null,
            stroke: null,
            medications: [],
            medicationsBool: null,
            smoking: null,
            cigerettesPerDay: null,
            alcohol: null,
            drinksPerDay: null,
            regularlyActive: null,
            activityPerWeek: null,
            cancer: null,
        },
        temp: {
            ageEntered: null,
            ageValidationError: 0
        },
        step: 0,
        status: "in-progress",
        FHIRBundle: null,
        registrationSuccess: 0,
        registrationError: null,
        abortEligibilityError: 0,
        abortAgeError: 0
    }),
    getters: {
        sexTranslated() {
            switch (this.subject.sex) {
                case 'male':
                    return 'Männlich';
                case 'female':
                    return 'Weiblich';
                case 'other':
                    return 'Divers';
                default:
                    return 'nicht verfügbar';
            }
        },
        dateLocalized() {
            const date = new Date(this.subject.dateOfBirth);
            let dateString = date.toLocaleDateString('at-DE');
            return dateString.replace(/\./g, '. ');
        },
        addressConstructed() {
            let fullAddress = this.subject.address.street + " " + this.subject.address.streetNr;
            if (this.subject.address.blockNr) {
                fullAddress += "/" + this.subject.address.blockNr;
            }
            if (this.subject.address.doorNr) {
                fullAddress += "/" + this.subject.address.doorNr;
            }
            fullAddress += ", ";
            fullAddress += this.subject.address.ZIP + " " + this.subject.address.city + ", " + this.subject.address.country;
            return fullAddress;
        },
        osName() {
            if (window.navigator.userAgent.includes("iPhone") || window.navigator.userAgent.includes("iPad")) {
                return "ios";
            } else if (window.navigator.userAgent.includes("Android")) {
                return "android";
            } else if (window.navigator.userAgent.includes("Windows Phone")) {
                return "windowsPhone";
            } else if (window.navigator.userAgent.includes("Windows")) {
                return "windows";
            } else {
                return "unknown";
            }
        },
        APIUrl() {
            return "https://" + this.$domain + "/account-request/signup/"
        }
    },
    actions: {
        constructFHIRBundle() {
            let tempFHIRBundle = {
                resourceType: "Bundle",
                type: "collection",
                entry: [
                    {
                        resource: {
                            resourceType: "Patient",
                            name: [
                                {
                                    family: this.subject.lastName,
                                    given: [
                                        this.subject.firstName
                                    ]
                                }
                            ],
                            telecom: [
                                {
                                    system: "email",
                                    value: this.subject.email
                                },
                                {
                                    system: "phone",
                                    value: this.subject.phone
                                },

                            ],
                            address: [
                                {
                                    line: [
                                        this.subject.address.street,
                                        this.subject.address.streetNr,
                                        this.subject.address.blockNr || "-",
                                        this.subject.address.doorNr || "-"
                                    ],
                                    postalCode: this.subject.address.ZIP,
                                    city: this.subject.address.city,
                                    country: this.subject.address.country,
                                    text: this.addressConstructed
                                },

                            ],
                            gender: this.subject.sex,
                            birthDate: this.subject.dateOfBirth,
                        }
                    },
                    {
                        resource: {
                            resourceType: "QuestionnaireResponse",
                            questionnaire: "https://fhir.ehealth-systems.at/artifacts/Questionnaire/ADHP-Registration",
                            status: this.status,
                            item: [
                                {
                                    linkId: "1.1",
                                    answer: [
                                        {
                                            valueInteger: this.subject.age
                                        }
                                    ]
                                },
                                {
                                    linkId: "1.2",
                                    answer: [
                                        {
                                            valueBoolean: this.eligibility.afib
                                        }
                                    ]
                                },
                                {
                                    linkId: "1.3",
                                    answer: [
                                        {
                                            valueString: this.eligibility.anticoagulant
                                        }
                                    ]
                                },
                                {
                                    linkId: "2.1",
                                    answer: [
                                        {
                                            valueString: this.subject.firstName
                                        }
                                    ]
                                },
                                {
                                    linkId: "2.2",
                                    answer: [
                                        {
                                            valueString: this.subject.lastName
                                        }
                                    ]
                                },
                                {
                                    linkId: "2.3",
                                    answer: [
                                        {
                                            valueDate: this.subject.dateOfBirth
                                        }
                                    ]
                                },
                                {
                                    linkId: "2.4",
                                    answer: [
                                        {
                                            valueInteger: parseInt(this.subject.socialSecurity)
                                        }
                                    ]
                                },
                                {
                                    linkId: "2.5",
                                    answer: [
                                        {
                                            valueString: this.subject.sex
                                        }
                                    ]
                                },
                                {
                                    linkId: "2.6",
                                    answer: [
                                        {
                                            valueString: this.subject.email
                                        }
                                    ]
                                },
                                {
                                    linkId: "2.7",
                                    answer: [
                                        {
                                            valueString: this.subject.phone
                                        }
                                    ]
                                },
                                {
                                    linkId: "2.8",
                                    answer: [
                                        {
                                            valueString: this.addressConstructed
                                        }
                                    ]
                                },
                                {
                                    linkId: "2.9",
                                    answer: [
                                        {
                                            valueString: this.subject.signature
                                        }
                                    ]
                                },
                                {
                                    linkId: "3.1",
                                    answer: [
                                        {
                                            valueInteger: this.subject.height
                                        }
                                    ]
                                },
                                {
                                    linkId: "3.2",
                                    answer: [
                                        {
                                            valueInteger: this.subject.bodyweight
                                        }
                                    ]
                                },
                                {
                                    linkId: "3.3",
                                    answer: [
                                        {
                                            valueBoolean: this.risk.angiopathyConfirmed
                                        }
                                    ]
                                },
                                {
                                    linkId: "3.4",
                                    answer: [
                                        {
                                            valueString: this.risk.angiopathy
                                        }
                                    ]
                                },
                                {
                                    linkId: "3.5",
                                    answer: [
                                        {
                                            valueBoolean: this.risk.pacemaker
                                        }
                                    ]
                                },
                                {
                                    linkId: "3.6",
                                    answer: [
                                        {
                                            valueBoolean: this.risk.diabetes
                                        }
                                    ]
                                },
                                {
                                    linkId: "3.7",
                                    answer: [
                                        {
                                            valueBoolean: this.risk.myocardialInfarction
                                        }
                                    ]
                                },
                                {
                                    linkId: "3.8",
                                    answer: [
                                        {
                                            valueBoolean: this.risk.hypertension
                                        }
                                    ]
                                },
                                {
                                    linkId: "3.9",
                                    answer: [
                                        {
                                            valueBoolean: this.risk.heartFailure
                                        }
                                    ]
                                },
                                {
                                    linkId: "3.10",
                                    answer: [
                                        {
                                            valueBoolean: this.risk.stroke
                                        }
                                    ]
                                },
                                {
                                    linkId: "3.11",
                                    answer: [
                                    ]
                                },
                                {
                                    linkId: "3.12",
                                    answer: [
                                        {
                                            valueBoolean: this.risk.smoking
                                        }
                                    ]
                                },
                                {
                                    linkId: "3.13",
                                    answer: [
                                        {
                                            valueBoolean: this.risk.alcohol
                                        }
                                    ]
                                },
                                {
                                    linkId: "3.14",
                                    answer: [
                                        {
                                            valueBoolean: this.risk.cancer
                                        }
                                    ]
                                },
                                {
                                    linkId: "3.15",
                                    answer: [
                                        {
                                            valueBoolean: this.risk.regularlyActive
                                        }
                                    ]
                                },
                                {
                                    linkId: "4.1",
                                    answer: [
                                        {
                                            valueString: this.osName
                                        }
                                    ]
                                },
                                {
                                    linkId: "4.2",
                                    answer: [
                                        {
                                            valueString: "Studiengruppe" // Placeholder for now.
                                        }
                                    ]
                                },
                            ]
                        }
                    }
                ]
            }
            this.addMedication(tempFHIRBundle);
            this.addConditionalAnswers(tempFHIRBundle);
            this.FHIRBundle = tempFHIRBundle;
        },
        async registerPerson() {
            await axios
                .put(this.APIUrl,
                    this.FHIRBundle, {
                    headers: {
                        'Content-Type': 'application/fhir+json'
                    }
                }
                )
                .then(response => {
                    if (response.status == 201) {
                        this.registrationSuccess = 1
                    } else {
                        this.registrationError = response.status
                        this.registrationSuccess = 3
                    }
                })
                .catch(error => {
                    this.registrationError = error.code
                    this.registrationSuccess = 3
                })
        },
        addMedication(tempFHIRBundle) {
            this.risk.medications.forEach((medication) =>
                tempFHIRBundle.entry[1].resource.item[22].answer.push(
                    {
                        valueCoding: {
                            code: medication,
                            system: "urn:uuid:81c157f1-8ccc-4cbf-a128-726d83ad6df7" // this needs to be addressed differently, hardcoded as a workaround
                        }
                    }
                )
            )

        },
        addConditionalAnswers(tempFHIRBundle) {
            let cigerettesPerDay = this.risk.cigerettesPerDay || "0"
            tempFHIRBundle.entry[1].resource.item.push(
                {
                    linkId: "3.12.1",
                    answer: [
                        {
                            valueString: cigerettesPerDay,
                        }
                    ]
                }
            )
            let drinksPerDay = this.risk.drinksPerDay || "0"
            tempFHIRBundle.entry[1].resource.item.push(
                {
                    linkId: "3.13.1",
                    answer: [
                        {
                            valueString: drinksPerDay,
                        }
                    ]
                }
            )
            let activityPerWeek = this.risk.activityPerWeek || "0"
            tempFHIRBundle.entry[1].resource.item.push(
                {
                    linkId: "3.15.1",
                    answer: [
                        {
                            valueString: activityPerWeek
                        }
                    ]
                }
            )
        },
        printAPICall() {
            // console.log(this.FHIRBundle)
            console.log(JSON.stringify(this.FHIRBundle))
        }
    },
    persist: true,
})