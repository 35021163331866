export const samplePersons = [

    {
        firstName: "Franz",
        lastName: "Mustermann",
        dateOfBirth: "1955-07-28",
        socialSecurity: "7829280755",
        sex: "male",
        phone: "06641234567",
        street: "Musterstraße",
        streetNr: "24",
        blockNr: "1",
        doorNr: "12",
        ZIP: 1010,
        city: "Wien",
        country: "Österreich",
    },
    {
        firstName: "Johanna",
        lastName: "Mustermann",
        dateOfBirth: "1955-07-28",
        socialSecurity: "7829280755",
        sex: "female",
        phone: "06641234567",
        street: "Musterstraße",
        streetNr: "24",
        blockNr: "1",
        doorNr: "12",
        ZIP: 1010,
        city: "Wien",
        country: "Österreich",
    },
    {
        firstName: "Franz-Joseph",
        lastName: "Kaiser",
        dateOfBirth: "1954-11-24",
        socialSecurity: "2137241154",
        sex: "male",
        phone: "06647395012",
        street: "Palastplatz",
        streetNr: "1",
        blockNr: "",
        doorNr: "",
        ZIP: 6020,
        city: "Innsbruck",
        country: "Österreich",
    },
    {
        firstName: "Elisabeth",
        lastName: "Kaiserin",
        dateOfBirth: "1954-11-24",
        socialSecurity: "2137241154",
        sex: "female",
        phone: "066457295821",
        street: "Palastplatz",
        streetNr: "1",
        blockNr: "",
        doorNr: "",
        ZIP: 6020,
        city: "Innsbruck",
        country: "Österreich",
    },
    {
        firstName: "Maximillian",
        lastName: "Kopf",
        dateOfBirth: "1949-01-09",
        socialSecurity: "6912090149",
        sex: "male",
        phone: "06648574633",
        street: "Fischergasse",
        streetNr: "12A",
        blockNr: "",
        doorNr: "1",
        ZIP: 8010,
        city: "Graz",
        country: "Österreich",
    },
    {
        firstName: "Renate",
        lastName: "Kopf",
        dateOfBirth: "1949-01-09",
        socialSecurity: "6912090149",
        sex: "female",
        phone: "066418576099",
        street: "Fischergasse",
        streetNr: "12A",
        blockNr: "",
        doorNr: "1",
        ZIP: 8010,
        city: "Graz",
        country: "Österreich",
    },
    {
        firstName: "Charly",
        lastName: "Koller",
        dateOfBirth: "1952-05-14",
        socialSecurity: "8748140552",
        sex: "other",
        phone: "066416748567",
        street: "Hauptplatz",
        streetNr: "52",
        blockNr: "2",
        doorNr: "5",
        ZIP: 4020,
        city: "Linz",
        country: "Österreich",
    },
    {
        firstName: "Toni",
        lastName: "Schmid",
        dateOfBirth: "1952-05-14",
        socialSecurity: "8748140552",
        sex: "other",
        phone: "066467366941",
        street: "Hauptplatz",
        streetNr: "52",
        blockNr: "2",
        doorNr: "5",
        ZIP: 4020,
        city: "Linz",
        country: "Österreich",
    },
]