<template>
  <h2>Sie wurden der Studiengruppe zugeteilt!</h2>
  <div class="row justify-content-center align-items-center mt-5">
    <div class="col-3 col-md-2">
      <img src="img/smartphoneHeart.png" class="img-responsive fit-image" />
    </div>
    <div class="col-9">
      Sie werden Ihr eigenes Smartphone benutzen, um Ihren Herzrhythmus zu
      überwachen und Nachrichten zu empfangen.
    </div>
  </div>
  <div class="row justify-content-center align-items-center mt-5 mb-3">
    <div class="col-3 col-md-2">
      <img src="img/heartCheck.png" class="img-responsive fit-image" />
    </div>
    <div class="col-9">
      Sie bekommen Gesundheitstipps und Vorschläge für Ihre Herzgesundheit.
    </div>
  </div>
  <button
    @click.prevent="store.step++"
    class="btn btn-proceed btn-lg w-100 mt-5 mb-3"
  >
    Wie's weiter geht
    <font-awesome-icon icon="fa-circle-right" class="ps-2" />
  </button>

  <div
    v-if="store.registrationSuccess === 1"
    class="alert alert-dismissible alert-success alert-fixed"
  >
    <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
    <strong>Registrierung erfolgreich!</strong>
  </div>
  <div
    v-else-if="store.registrationSuccess === 2"
    class="alert alert-dismissible alert-primary alert-fixed"
  >
    <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
    <strong>Registrierung ist bereits erfolgt.</strong>
  </div>
  <div
    v-else-if="store.registrationSuccess === 3"
    class="alert alert-dismissible alert-danger alert-fixed"
  >
    <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
    <strong
      >Registrierung fehlgeschlagen! (Error-Code:
      {{ store.registrationError }})</strong
    >
  </div>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
import { onMounted } from "vue";

onMounted(() => {
  if (store.registrationSuccess === 0) {
    store.registerPerson();
  } else if (store.registrationSuccess === 1) {
    store.registrationSuccess = 2;
  }
});
</script>

<style scoped>
.fit-image {
  width: 100%;
  object-fit: cover;
}

.alert-fixed {
  opacity: 0.95;
  position: fixed;
  bottom: 5%;
  left: 10%;
  width: 80%;
  z-index: 9999;
  border-radius: 0px;
  font-size: 14px;
}
</style>
