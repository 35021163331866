<template>
  <h2>Überprüfen Sie Ihre Daten nochmals:</h2>
  <h6>
    Die Teilnahme an Mein-Puls gelingt nur, wenn wir Sie erreichen können.
  </h6>
  <div class="confirm-info text-center my-4">
    <span
      ><b>Name:</b> {{ store.subject.firstName }}
      {{ store.subject.lastName }}</span
    ><br />
    <span><b>Geburtsdatum:</b> {{ store.dateLocalized }}</span
    ><br />
    <span
      ><b>Sozialversicherungsnummer:</b>
      {{ store.subject.socialSecurity }}</span
    ><br />
    <span><b>Geschlecht: </b> {{ store.sexTranslated }}</span
    ><br />
    <span><b>E-Mail-Adresse: </b> {{ store.subject.email }}</span
    ><br />
    <span><b>Telefonnummer: </b> {{ store.subject.phone }}</span
    ><br />
    <span><b>Adresse: </b> {{ store.addressConstructed }}</span
    ><br />
  </div>
  <button
    @click.prevent="store.step++"
    class="btn btn-proceed btn-lg w-100 mt-4 mb-3"
  >
    <font-awesome-icon icon="fa-circle-check" class="pe-2" />
    Eingabe bestätigen
  </button>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped>
.confirm-info {
  font-size: 14px;
  line-height: 2;
}
</style>
