<template>
  <button
    @click.prevent="next()"
    class="btn btn-proceed btn-lg w-100 mt-4 mb-3"
  >
    Weiter
  </button>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();

function next() {
  store.step++;
  if (store.step === 5) {
    checkAgeAbort();
  }
  if (store.step === 11) {
    checkCriteriaAbort();
  }
}

function checkAgeAbort() {
  if (store.subject.age < 65) {
    store.abortAgeError = 1;
    store.step = 0;
  }
}

function checkCriteriaAbort() {
  if (
    store.eligibility.afib === true ||
    store.eligibility.anticoagulant !== "none"
  ) {
    store.abortEligibilityError = 1;
    store.step = 0;
  }
}
</script>

<style scoped></style>
