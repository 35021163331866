import { createApp } from 'vue';
import App from './App.vue';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueSignaturePad from 'vue-signature-pad';

const app = createApp(App)
app.config.globalProperties.$domain = process.env.VUE_APP_BACKEND_DOMAIN;

library.add(fas)
const pinia = createPinia()
pinia.use(({ store }) => {
    store.$domain = app.config.globalProperties.$domain;
});
pinia.use(piniaPluginPersistedstate)
app.use(pinia)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(VueSignaturePad)
app.mount('#app')
