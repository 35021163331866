export const riskQuestions = [
    {
        "type": "bool",
        "text": "Wurde bei Ihnen eine Gefäßerkrankung am Herzen mit CT oder Herzkatheter festgestellt?",
        "fieldName": "angiopathyConfirmed",
        "franzMessage": "Platzhalter",
    }, {
        "type": "boolUncertainty",
        "text": "Wurde bei Ihnen eine andere Gefäßerkrankung fesgestellt?",
        "fieldName": "angiopathy",
        "franzMessage": "Platzhalter",
    },
    {
        "type": "bool",
        "text": "Haben Sie einen Herzschrittmacher?",
        "fieldName": "pacemaker",
        "franzMessage": "Platzhalter",
    },
    {
        "type": "bool",
        "text": "Haben Sie Diabetes oder werden deshalb behandelt?",
        "fieldName": "diabetes",
        "franzMessage": "Platzhalter",
    },
    {
        "type": "bool",
        "text": "Hatten Sie schon einen Herzinfarkt?",
        "fieldName": "myocardialInfarction",
        "franzMessage": "Platzhalter",
    },
    {
        "type": "bool",
        "text": "Haben Sie Bluthochdruck oder werden deshalb behandelt?",
        "fieldName": "hypertension",
        "franzMessage": "Platzhalter",
    },
    {
        "type": "bool",
        "text": "Leiden Sie an Herzschwäche oder werden deshalb behandelt?",
        "fieldName": "heartFailure",
        "franzMessage": "Platzhalter",
    },
    {
        "type": "bool",
        "text": "Hatten Sie schon einen Schlaganfall?",
        "fieldName": "stroke",
        "franzMessage": "Platzhalter",
    },
    {
        "type": "multipleChoiceMedication",
        "text": "Nehmen Sie die folgenden Medikamente?",
        "fieldName": "medications",
        "franzMessage": "",
    },
    {
        "type": "boolExtended",
        "text": "Rauchen Sie?",
        "extendedText": "Wie viele Zigaretten rauchen Sie <b>pro Tag </b>?",
        "extendedLabels": ["<10", "10-20", "20-40", ">40"],
        "extendedValues": ["<10", "10-20", "20-40", ">40"],
        "fieldName": "smoking",
        "franzMessage": "Platzhalter",
    },
    {
        "type": "boolExtended",
        "text": "Trinken Sie regelmäßig Alkohol?",
        "extendedText": "Wie viele alkoholische Getränke trinken Sie <b>pro Tag</b>?",
        "extendedLabels": ["<2", "2-4", "4-6", ">6"],
        "extendedValues": ["<2", "2-4", "4-6", ">6"],
        "fieldName": "alcohol",
        "franzMessage": "Platzhalter",
    },
    {
        "type": "bool",
        "text": "Haben oder hatten Sie eine Krebserkrankung?",
        "fieldName": "cancer",
        "franzMessage": "Platzhalter",
    },
    {
        "type": "boolExtended",
        "text": "Machen Sie regelmäßig Sport oder gehen Sie spazieren?",
        "extendedText": "Wie oft betätigen Sie sich körperlich <b>in der Woche</b>?",
        "extendedLabels": ["<2", "2-4", "4-6", ">6"],
        "extendedValues": ["<2", "2-4", "4-6", ">6"],
        "fieldName": "regularlyActive",
        "franzMessage": "Platzhalter",
    },
]
