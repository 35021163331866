<template>
  <h2 class="mt-4">Ist Ihr Alter korrekt?</h2>
  <h6 class="mb-4">Bitte überprüfen Sie Ihre Angaben.</h6>
  <div class="d-flex justify-content-center">
    <h3 class="my-4">Alter: {{ store.subject.age }}</h3>
  </div>
  <div class="row justify-content-center">
    <div class="col-5">
      <button
        class="btn btn-danger btn-lg w-100 mt-4 mb-5"
        style="height: 75px"
        @click.prevent="store.step--"
      >
        <font-awesome-icon icon="fa-arrow-left" class="px-1" /> Nein
      </button>
    </div>
    <div class="col-5">
      <button
        class="btn btn-success btn-lg w-100 mt-4 mb-5"
        style="height: 75px"
        @click.prevent="next()"
      >
        Ja <font-awesome-icon icon="fa-circle-check" class="px-1" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
function next() {
  store.step++;
  if (store.step === 5) {
    checkAgeAbort();
  }
  if (store.step === 11) {
    checkCriteriaAbort();
  }
}

function checkAgeAbort() {
  if (store.subject.age < 65) {
    store.abortAgeError = 1;
    store.step = 0;
  }
}

function checkCriteriaAbort() {
  if (
    store.eligibility.afib === true ||
    store.eligibility.anticoagulant !== "none"
  ) {
    store.abortEligibilityError = 1;
    store.step = 0;
  }
}
</script>

<style scoped></style>
