<template>
  <h2>Ihre Daten liegen uns am Herzen</h2>

  <div class="row mb-4 align-items-center">
    <h6>
      Wir nehmen den Schutz Ihrer Daten bei der Nutzung der
      <b>Mein-Puls App</b> sehr ernst.
    </h6>
  </div>
  <div class="info-text text-center">
    Ihre persönlichen Gesundheitsdaten werden sicher gespeichert und sind durch
    moderne Verschlüsselungstechnologien geschützt. Wir garantieren, dass Ihre
    Daten nicht an Dritte weitergegeben werden und nicht für kommerzielle Zwecke
    verwendet werden. Ihre Informationen werden ausschließlich zu
    Forschungszwecken genutzt, um die medizinische Wissenschaft voranzubringen
    und die Gesundheitsversorgung zu verbessern.
    <br /><br />Ihre Privatsphäre ist uns wichtig, und wir setzen alles daran,
    Ihre Daten sicher und vertraulich zu behandeln.
  </div>
</template>

<script setup></script>

<style scoped>
.fit-image {
  width: 100%;
  object-fit: cover;
}

.info-text {
  font-size: 13px;
}
</style>
