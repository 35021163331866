<template>
  <h6>Frage 1 von 14</h6>
  <h5 class="my-3">Geben Sie Ihre geschätzte Größe und Gewicht an:</h5>
  <form
    class="needs-validation"
    @submit.prevent="validateHeightWeightCriteria"
    novalidate
  >
    <div class="form-floating mt-4 mb-4">
      <input
        type="number"
        class="form-control"
        :class="[
          heightError === 1 ? 'is-invalid' : '',
          heightError === 2 ? 'is-valid' : '',
        ]"
        id="floatingInput"
        placeholder="175"
        v-model="store.subject.height"
      />
      <label for="floatingInput">Größe (in cm)</label>
      <div class="invalid-feedback">
        Bitte geben Sie Ihre korrekte Größe in cm an (z.B. 175).
      </div>
    </div>
    <div class="form-floating mt-4 mb-4">
      <input
        type="number"
        class="form-control"
        :class="[
          bodyweightError === 1 ? 'is-invalid' : '',
          bodyweightError === 2 ? 'is-valid' : '',
        ]"
        id="floatingInput"
        placeholder="75"
        v-model="store.subject.bodyweight"
      />
      <label for="floatingInput">Gewicht (in kg)</label>
      <div class="invalid-feedback">
        Bitte geben Sie Ihr korrektes Gewicht in kg an (z.B. 80).
      </div>
    </div>
    <br />
    <button
      type="submit"
      :disabled="isFormInvalid"
      class="btn btn-proceed btn-lg w-100 mt-3"
    >
      Weiter
    </button>
  </form>
  <FranzButton :message="'Platzhalter'" />
</template>

<script setup>
import FranzButton from "@/components/utils/FranzButton.vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
import { ref, computed } from "vue";
const store = dhRegistrationStore();
const heightError = ref(0);
const bodyweightError = ref(0);
const isFormInvalid = computed(() => {
  return !store.subject.height || !store.subject.bodyweight;
});

function validateHeightWeightCriteria() {
  if (
    store.subject.height < 60 ||
    store.subject.height > 250 ||
    store.subject.height !== parseInt(store.subject.height, 10)
  ) {
    heightError.value = 1;
  } else {
    heightError.value = 2;
  }
  if (
    store.subject.bodyweight < 25 ||
    store.subject.bodyweight > 400 ||
    store.subject.bodyweight !== parseInt(store.subject.bodyweight, 10)
  ) {
    bodyweightError.value = 1;
  } else {
    bodyweightError.value = 2;
  }

  if (heightError.value === 2 && bodyweightError.value === 2) {
    store.step++;
  }
}
</script>

<style scoped>
.btn-check + .btn {
  color: #142b58;
  border-color: #142b58;
  height: 60px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-check:unchecked + .btn {
  color: #142b58;
}
.btn-check:checked + .btn {
  color: white;
  background-color: #142b58;
}
</style>
