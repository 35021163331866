<template>
  <h5 class="my-3">{{ props.question.text }}</h5>
  <div class="btn-group btn-group-question mb-3" role="group">
    <input
      type="radio"
      class="btn-check"
      :id="props.question.fieldName + '-y'"
      :value="true"
      v-model="localValue"
      @change="updateStore"
    />
    <label
      class="btn btn-outline-primary"
      :for="props.question.fieldName + '-y'"
      >Ja</label
    >
    <input
      type="radio"
      class="btn-check"
      :id="props.question.fieldName + '-n'"
      :value="false"
      v-model="localValue"
      @change="updateStore"
    />
    <label
      class="btn btn-outline-primary"
      :for="props.question.fieldName + '-n'"
      >Nein</label
    >
  </div>
  <button
    v-if="localValue !== null"
    @click.prevent="store.step++"
    :disabled="localValue === null"
    class="btn btn-proceed btn-lg w-100 mt-5"
  >
    Weiter
  </button>
  <FranzButton :message="props.question.franzMessage" />
</template>

<script setup>
import FranzButton from "../utils/FranzButton.vue";

import { defineProps, ref, onMounted } from "vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
const localValue = ref(null);

const props = defineProps({
  question: Object,
});

onMounted(() => {
  switch (props.question.fieldName) {
    case "angiopathyConfirmed":
      localValue.value = store.risk.angiopathyConfirmed;
      break;
    case "angiopathy":
      localValue.value = store.risk.angiopathy;
      break;
    case "pacemaker":
      localValue.value = store.risk.pacemaker;
      break;
    case "diabetes":
      localValue.value = store.risk.diabetes;
      break;
    case "myocardialInfarction":
      localValue.value = store.risk.myocardialInfarction;
      break;
    case "hypertension":
      localValue.value = store.risk.hypertension;
      break;
    case "heartFailure":
      localValue.value = store.risk.heartFailure;
      break;
    case "stroke":
      localValue.value = store.risk.stroke;
      break;
    case "cancer":
      localValue.value = store.risk.cancer;
      break;
    default:
      localValue.value = null;
  }
});

function updateStore() {
  switch (props.question.fieldName) {
    case "angiopathyConfirmed":
      store.risk.angiopathyConfirmed = localValue.value;
      break;
    case "angiopathy":
      store.risk.angiopathy = localValue.value;
      break;
    case "pacemaker":
      store.risk.pacemaker = localValue.value;
      break;
    case "diabetes":
      store.risk.diabetes = localValue.value;
      break;
    case "myocardialInfarction":
      store.risk.myocardialInfarction = localValue.value;
      break;
    case "hypertension":
      store.risk.hypertension = localValue.value;
      break;
    case "heartFailure":
      store.risk.heartFailure = localValue.value;
      break;
    case "stroke":
      store.risk.stroke = localValue.value;
      break;
    case "cancer":
      store.risk.cancer = localValue.value;
      break;
    default:
      return null;
  }
  store.step++;
}
</script>

<style scoped>
.btn-check + .btn {
  color: #142b58;
  border-color: #142b58;
  height: 60px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-check:unchecked + .btn {
  color: #142b58;
}
.btn-check:checked + .btn {
  color: white;
  background-color: #142b58;
}
</style>
