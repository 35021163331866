<template>
  <div
    class="modal fade"
    id="restartModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <h6 class="mb-4">
            Sind Sie sicher, dass Sie die Registrierung neu starten möchten?
          </h6>
          Ihre eingegebenen Daten werden dadurch gelöscht.
        </div>
        <div class="modal-footer py-3">
          <button
            type="button"
            class="btn btn-outline-dark w-100"
            data-bs-dismiss="modal"
          >
            Nein, Registierung fortführen
          </button>

          <button
            type="button"
            class="btn btn-danger w-100"
            data-bs-dismiss="modal"
            @click.prevent="store.$reset"
          >
            Ja, neu anfangen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped></style>
