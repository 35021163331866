<template>
  <h2>Los geht's!</h2>
  <h6>
    Wir möchten Ihnen zwei weitere kurze Fragen zu Ihren Vorerkrankungen
    stellen.
  </h6>
</template>

<script>
import { onMounted, ref } from "vue";
import { Popover } from "bootstrap";

export default {
  setup() {
    const popoverLink = ref(null);
    let popoverInstance = null;

    const togglePopover = (event) => {
      event.stopPropagation();
      if (popoverInstance) {
        popoverInstance.toggle();
      }
    };

    const hidePopover = () => {
      if (popoverInstance) {
        popoverInstance.hide();
      }
    };

    onMounted(() => {
      if (popoverLink.value) {
        popoverInstance = new Popover(popoverLink.value, {
          trigger: "manual",
        });
      }
      document.addEventListener("click", hidePopover);
    });

    return {
      popoverLink,
      togglePopover,
    };
  },
};
</script>

<style scoped>
.fit-image {
  width: 100%;
  object-fit: cover;
}
</style>
