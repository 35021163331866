<template>
  <div
    class="modal fade"
    id="ageChangeModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="ageChangeModalLabel">
            Überprüfen Sie Ihre Angaben
          </h5>
        </div>
        <div class="modal-body">
          Ihr zu Beginn eingegebenes Alter passt nicht zu Ihrem Geburtsdatum.
          Möchten Sie Ihr Alter mit dem angegebenen Geburtsdatum aktualisieren?
        </div>
        <div class="row justify-content-center align-items-center mb-5 mt-3">
          <div class="col-4 px-0">
            <div class="form-floating">
              <input
                type="number"
                class="form-control"
                id="floatingInput"
                disabled="true"
                v-model="store.temp.ageEntered"
              />
              <label for="floatingInput">Errechnetes Alter</label>
            </div>
          </div>
          <div class="col-2 px-0 text-center">
            <font-awesome-icon
              icon="caret-right"
              class="px-0 pe-1"
              style="font-size: 2.5em"
            />
          </div>
          <div class="col-4 px-0">
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                id="floatingInput"
                disabled="true"
                v-model="store.subject.age"
              />
              <label for="floatingInput">Angegebenes Alter</label>
            </div>
          </div>
        </div>
        <div class="modal-footer py-2">
          <button
            type="button"
            class="btn btn-outline-dark"
            data-bs-dismiss="modal"
          >
            Zurück
          </button>
          <button
            type="button"
            class="btn btn-success"
            @click="updateAge"
            data-bs-dismiss="modal"
          >
            Aktualisieren
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();

function updateAge() {
  store.subject.age = store.temp.ageEntered;
  store.temp.ageValidationError = 2;
  store.step++;
}
</script>

<style scoped>
.modal-body {
  font-size: 10pt;
}

label {
  font-size: 9pt;
}
</style>
