<template>
  <transition>
    <section v-if="store.step === 11">
      <ConsentGratulationScreen />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 12">
      <ConsentVideoScreen /><br />
      <NextButton class="mb-5" />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 13">
      <ConsentStartScreen />
      <NextButton class="mb-5" />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 14">
      <ConsentDocumentScreen />
      <NextButton />
      <CancelButton />
    </section>
  </transition>
  <transition>
    <section v-if="store.step === 15">
      <ConsentDataAppealScreen />
      <NextButton class="mb-5" />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 17">
      <ConsentContactScreen />
      <CancelButton />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 16">
      <ConsentDataScreen />
      <CancelButton />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 18">
      <ConsentContactConfirmScreen />
      <CancelButton />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 19">
      <ConsentSignatureScreen />
      <CancelButton />
    </section>
  </transition>
</template>

<script setup>
import ConsentStartScreen from "@/components/screens/consent/ConsentStartScreen.vue";
import ConsentVideoScreen from "@/components/screens/consent/ConsentVideoScreen.vue";
import ConsentDataAppealScreen from "@/components/screens/consent/ConsentDataAppealScreen.vue";
import ConsentDocumentScreen from "@/components/screens/consent/ConsentDocumentScreen.vue";
import ConsentGratulationScreen from "@/components/screens/consent/ConsentGratulationScreen.vue";
import ConsentContactScreen from "@/components/screens/consent/ConsentContactScreen.vue";
import ConsentContactConfirmScreen from "@/components/screens/consent/ConsentContactConfirmScreen.vue";
import ConsentDataScreen from "@/components/screens/consent/ConsentDataScreen.vue";
import ConsentSignatureScreen from "@/components/screens/consent/ConsentSignatureScreen.vue";
import NextButton from "@/components/navigation/NextButton.vue";
import CancelButton from "@/components/navigation/CancelButton.vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped></style>
