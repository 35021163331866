<template>
  <h2>Sind diese Informationen korrekt?</h2>
  <h6>Bitte überprüfen Sie Ihre Angaben.</h6>

  <div class="row mt-4 justify-content-center">
    <div class="col-12">
      <div class="row justify-content-center text-center">
        Haben Sie Vorhofflimmern?
      </div>
      <div class="row">
        <div class="btn-group btn-group-question m-0 my-2" role="group">
          <input
            type="radio"
            class="btn-check"
            id="afib-y"
            :value="true"
            v-model="store.eligibility.afib"
            :disabled="editDisabled"
          />
          <label class="btn btn-outline-primary" for="afib-y">Ja</label>
          <input
            type="radio"
            class="btn-check"
            id="afib-n"
            :value="false"
            v-model="store.eligibility.afib"
            :disabled="editDisabled"
          />
          <label class="btn btn-outline-primary py-0" for="afib-n">Nein</label>
        </div>
      </div>
    </div>
  </div>
  <div class="row my-4 justify-content-center">
    <div class="col-12">
      <div class="row justify-content-center text-center">
        Nehmen Sie Blutverdünner?
      </div>
      <div class="btn-group btn-group-question m-0 my-2" role="group">
        <input
          type="radio"
          class="btn-check"
          id="anticoagulantBool-y"
          :value="true"
          @click="store.eligibility.anticoagulant = ''"
          v-model="store.eligibility.anticoagulantBool"
          :disabled="editDisabled"
        />
        <label class="btn btn-outline-primary" for="anticoagulantBool-y"
          >Ja</label
        >
        <input
          type="radio"
          class="btn-check"
          id="anticoagulantBool-n"
          :value="false"
          @click="store.eligibility.anticoagulant = 'none'"
          v-model="store.eligibility.anticoagulantBool"
          :disabled="editDisabled"
        />
        <label class="btn btn-outline-primary py-0" for="anticoagulantBool-n"
          >Nein</label
        >
      </div>
      <div class="row mt-3" v-if="store.eligibility.anticoagulantBool === true">
        <div class="form-group form-floating">
          <select
            class="form-select"
            v-model="store.eligibility.anticoagulant"
            id="anticoagulantSelect"
            :disabled="editDisabled"
          >
            <option value="xarelto">Xarelto</option>
            <option value="pradaxa">Pradaxa</option>
            <option value="eliquis">Eliquis</option>
            <option value="lixiana">Lixiana</option>
            <option value="sintrom">Sintrom</option>
          </select>
          <label class="ms-2" for="anticoagulantSelect"
            >Blutverdünner auswählen...</label
          >
        </div>
      </div>
    </div>
  </div>
  <button
    type="button"
    class="btn btn-lg w-100 mt-2 mb-3"
    :class="[editDisabled ? 'btn-danger' : 'btn-success']"
    @click="switchMode"
  >
    <span v-if="editDisabled">
      <font-awesome-icon icon="fa-pen" class="pe-2" /> Daten bearbeiten
    </span>
    <span v-else>
      <font-awesome-icon icon="fa-circle-check" class="pe-2" /> Bearbeitung
      speichern
    </span>
  </button>
  <NextButton :disabled="isFormInvalid" />
</template>

<script setup>
import { computed } from "vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
import NextButton from "@/components/navigation/NextButton.vue";
const store = dhRegistrationStore();
import { ref } from "vue";

const editDisabled = ref(true);

const switchMode = () => {
  editDisabled.value = !editDisabled.value;
};

const isFormInvalid = computed(() => {
  return (
    store.eligibility.anticoagulantBool === true &&
    store.eligibility.anticoagulant === ""
  );
});
</script>

<style scoped>
.btn-check + .btn {
  color: #142b58;
  border-color: #142b58;
  height: 60px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-check:unchecked + .btn {
  color: #142b58;
}
.btn-check:checked + .btn {
  color: white;
  background-color: #142b58;
}
</style>
