<template>
  <h2>Ihr Beitrag ist wichtig</h2>
  <h6>
    Basierend auf den von Ihnen eingegebenen Daten können Sie einen wertvollen
    Beitrag zur Verbesserung der Herzgesundheit in Österreich leisten. Zudem
    können Sie auch persönlich davon profitieren. Zur Teilnahme an diesem von
    der Medizinischen Universität Innsbruck geleiteten Programm ist Ihr
    Einverständnis erforderlich. Im nächsten Schritt werden Sie über wichtige
    Aspekte zur Teilnahme informiert und aufgeklärt.
  </h6>
  <div class="row justify-content-center mt-5">
    <div class="col col-md-3 col-lg-2">
      <img src="img/logoLBG.png" class="img-responsive fit-image" />
    </div>
    <div class="col col-md-3 col-lg-2">
      <img src="img/logoMUI.png" class="img-responsive fit-image" />
    </div>
    <div class="col col-md-3 col-lg-2">
      <img src="img/logoAIT.png" class="img-responsive fit-image" />
    </div>
  </div>
  <div class="row justify-content-center mt-5">
    <div class="col col-md-3 col-lg-2">
      <img src="img/logoMUG.png" class="img-responsive fit-image" />
    </div>
    <div class="col col-md-3 col-lg-2">
      <img src="img/logoUMIT.jpg" class="img-responsive fit-image" />
    </div>
    <div class="col col-md-3 col-lg-2">
      <img src="img/logoTirolKliniken.png" class="img-responsive fit-image" />
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
.fit-image {
  width: 100%;
  object-fit: cover;
}
</style>
