<template>
  <h5 class="mb-3">Nehmen Sie die folgenden Medikamente?</h5>
  <div class="text-center mb-1">Mehrfachauswahl möglich:</div>
  <div class="btn-group-vertical btn-group-question" role="group">
    <input
      type="checkbox"
      class="btn-check"
      id="acei"
      value="acei"
      @change="store.risk.medicationsBool = false"
      v-model="store.risk.medications"
    />
    <label class="btn btn-outline-primary" for="acei">ACEI/ARB</label>
    <input
      type="checkbox"
      class="btn-check"
      id="beta-blocker"
      value="beta_blocker"
      @change="store.risk.medicationsBool = false"
      v-model="store.risk.medications"
    />
    <label class="btn btn-outline-primary" for="beta-blocker"
      >Beta-Blocker</label
    >
    <input
      type="checkbox"
      class="btn-check"
      id="aspirin"
      value="aspirin"
      @change="store.risk.medicationsBool = false"
      v-model="store.risk.medications"
    />
    <label class="btn btn-outline-primary" for="aspirin">Aspirin</label>
    <input
      type="checkbox"
      class="btn-check"
      id="clopidogrel"
      value="clopidogrel"
      @change="store.risk.medicationsBool = false"
      v-model="store.risk.medications"
    />
    <label class="btn btn-outline-primary" for="clopidogrel">Clopidogrel</label>
    <input
      type="checkbox"
      class="btn-check"
      id="statin"
      value="statin"
      @change="store.risk.medicationsBool = false"
      v-model="store.risk.medications"
    />
    <label class="btn btn-outline-primary" for="statin">Statin</label>
  </div>

  <div class="btn-group-vertical btn-group-question" role="group">
    <input
      type="radio"
      class="btn-check"
      id="no_meds"
      value="none"
      @click="no_meds()"
      v-model="store.risk.medicationsBool"
    />
    <label class="btn btn-outline-danger" for="no_meds"
      >Keines dieser Medikamente</label
    >
  </div>

  <br />
  <button
    :disabled="store.risk.medicationsBool === null"
    @click.prevent="store.step++"
    class="btn btn-proceed btn-lg w-100 mt-4"
  >
    Weiter
  </button>
  <FranzButton :message="'Platzhalter'" />
</template>

<script setup>
import FranzButton from "@/components/utils/FranzButton.vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();

function no_meds() {
  store.risk.medications = [];
}
</script>

<style scoped>
.btn-check + .btn {
  color: #142b58;
  border-color: #142b58;
  height: 60px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-check:unchecked + .btn {
  color: #142b58;
}
.btn-check:checked + .btn {
  color: white;
  background-color: #142b58;
}
</style>
