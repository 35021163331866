<template>
  <div
    class="modal fade"
    id="cancelModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="cancelModalLabel">Sind Sie sicher?</h5>
        </div>
        <div class="modal-body">
          Wenn Sie ihre Zustimmung nicht geben, können Sie nicht an der Studie
          teilnehmen.
        </div>
        <div class="modal-footer py-2">
          <button
            type="button"
            class="btn btn-outline-dark"
            data-bs-dismiss="modal"
          >
            Zurück
          </button>

          <a href="../"
            ><button type="button" class="btn btn-danger">
              Registrierung abbrechen
            </button></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style scoped></style>
