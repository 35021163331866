<template>
  <h2>Aufklärungsvideo</h2>
  <video
    controls
    class="my-4"
    preload="none"
    poster="../../../../public/img/adhpLogo.png"
    playsinline
  >
    <source src="../../../../public/vid/hmt_sample.mp4" type="video/mp4" />
  </video>
  <div class="text-center info-text">
    <span>
      Das Video besitzt sowohl Ton, als auch Untertitel. Wenn Sie den Ton nutzen
      wollen, schalten Sie den Ton Ihres Telefons ein.Schauen Sie sich in Ruhe
      das Video so oft an, wie Sie möchten. Es dauert ungefähr 5 Minuten.
    </span>
  </div>
</template>

<script setup></script>

<style scoped>
video {
  width: 100%;
  height: 250px;
}
.info-text {
  font-size: 13px;
}
</style>
