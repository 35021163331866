<template>
  <button
    v-if="(store.step !== 0) & (store.step !== 37)"
    @click.prevent="goBack()"
    class="btn btn-outline-dark px-2"
    :disabled="store.status !== 'in-progress'"
  >
    <font-awesome-icon icon="fa-arrow-left" class="px-1" />
  </button>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();

function goBack() {
  if (store.step == 10 && store.eligibility.anticoagulantBool == false) {
    store.step -= 2;
  } else {
    store.step--;
  }
}
</script>

<style scoped></style>
