<template>
  <div class="row">
    <h2 class="mb-5">Danke für Ihr Interesse!</h2>
    <h6>
      Leider kommen Sie derzeit basierend auf den eingegebenen Daten nicht für
      <b>Mein-Puls</b> in Frage.
    </h6>
    <h6 class="my-4">{{ message }}</h6>
    <button
      class="btn btn-lg btn-proceed w-100 mt-5"
      @click.prevent="store.$reset"
    >
      Zurück zur Homepage
    </button>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
defineProps(["message"]);
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped></style>
