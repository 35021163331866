<template>
  <transition>
    <section v-if="store.step === 20">
      <RiskStartScreen /><br />
      <NextButton />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 21"><RiskHeightWeightScreen /><br /></section>
  </transition>

  <span v-for="(risk, index) in riskQuestions" :key="risk">
    <transition>
      <section v-if="store.step === index + 22">
        <h6>Frage {{ index + 2 }} von {{ riskQuestions.length + 1 }}</h6>
        <BoolQuestion v-if="risk.type === 'bool'" :question="risk" />
        <RiskMedicationScreen v-if="risk.type === 'multipleChoiceMedication'" />
        <RiskAngiopathyScreen v-if="risk.type === 'boolUncertainty'" />
        <ExtendedBoolQuestion
          v-if="risk.type === 'boolExtended'"
          :question="risk"
        />
      </section>
    </transition>
  </span>
</template>

<script setup>
import RiskStartScreen from "@/components/screens/risk/RiskStartScreen.vue";
import RiskHeightWeightScreen from "@/components/screens/risk/RiskHeightWeightScreen.vue";
import NextButton from "@/components/navigation/NextButton.vue";
import BoolQuestion from "@/components/screen_composables/BoolQuestion.vue";
import ExtendedBoolQuestion from "@/components/screen_composables/ExtendedBoolQuestion.vue";
import RiskMedicationScreen from "@/components/screens/risk/RiskMedicationScreen.vue";
import RiskAngiopathyScreen from "@/components/screens/risk/RiskAngiopathyScreen.vue";
import { riskQuestions } from "@/assets/riskList";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped></style>
