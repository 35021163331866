<template>
  <h2>
    Applikation gesperrt
    <font-awesome-icon icon="fa-lock" class="mx-2" />
  </h2>
  <div class="text-center">
    Bitte geben Sie das <b>Passwort</b> ein, um die Applikation zu starten:
    <form class="needs-validation" @submit.prevent="checkPhrase" novalidate>
      <div class="input-group my-4">
        <div class="form-floating">
          <input
            :type="phraseHidden ? 'password' : 'text'"
            class="form-control"
            :class="[
              phraseError === 1 ? 'is-invalid' : '',
              phraseError === 2 ? 'is-valid' : '',
            ]"
            v-model="lockPhrase"
            placeholder="123456"
          />
          <label for="floatingInput">Passwort</label>
        </div>

        <button
          class="btn btn-outline-secondary"
          type="button"
          @click="phraseHidden = !phraseHidden"
        >
          <font-awesome-icon
            :icon="phraseHidden ? ['fas', 'eye'] : ['fas', 'eye-slash']"
          />
        </button>
      </div>
    </form>
  </div>

  <div class="text-danger text-center" :class="phraseError < 1 ? 'd-none' : ''">
    <span v-if="phraseError === 1">Das Passwort ist nicht korrekt.</span>
  </div>
  <button
    type="submit"
    class="btn btn-proceed btn-lg w-100 mt-5"
    @click.prevent="checkPhrase"
    :disabled="!lockPhrase"
  >
    Weiter
  </button>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
import { ref } from "vue";
const phraseHidden = ref(true);
const lockPhrase = ref("");
const phraseError = ref(0);

function checkPhrase() {
  if (lockPhrase.value !== store.lockPhrase) {
    phraseError.value = 1;
  } else {
    store.step++;
  }
}
</script>

<style scoped>
.input-group-addon i {
  margin-left: -30px;
  cursor: pointer;
  z-index: 200;
  position: absolute;
  font-size: large;
  color: #687179;
}
.display-eye {
  cursor: pointer;
}
</style>
