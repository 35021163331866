<template>
  <h2>Fast geschafft!</h2>
  <div class="text-center">
    Blieben Sie dran - wir haben nur noch ein paar Fragen, die wir Ihnen stellen
    möchten, bevor Sie <b>Mein-Puls</b> teilnehmen können.<br /><br />
    <div class="row my-4">
      <div class="col-7">
        In Teil 2 stellen wir Ihnen wichtige Fragen zu Ihrer Gesundheit, um Ihr
        individuelles Risiko abzuschätzen.
      </div>
      <div class="col-5 text-center">
        Dauer: <br />5 Minuten<br />
        <font-awesome-icon
          icon="fa-clock"
          style="font-size: 2em"
          class="mt-3"
        />
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style scoped></style>
