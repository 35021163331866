<template>
  <div class="row mb-5 justify-content-center">
    <div class="col-5 text-end justify-content-end align-items-center d-flex">
      <img src="img/adhpLogo.png" class="img-responsive fit-image" />
    </div>
    <div class="col-7">
      <h1>Mein Puls</h1>
      <h6>www.mein-puls.at</h6>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <h2 class="mb-5">Herz-lich Willkommen!</h2>
      <h5>Starten Sie mit der Registrierung zur Teilnahme an der Studie.</h5>
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
video {
  width: 100%;
  height: 250px;
}
.fit-image {
  max-width: 75px;
}

h6 {
  text-align: start !important;
}
</style>
