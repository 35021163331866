<template>
  <h2 class="mt-4">Worum geht's?</h2>
  <div class="row mt-3">
    <div class="col-12">
      <div class="text-center">
        Sehen Sie sich dieses kurze Video an, um das Wichtigste über die Studie
        zu erfahren!
      </div>
    </div>
  </div>
  <video
    controls
    class="my-4"
    preload="none"
    poster="../../../../public/img/adhpLogo.png"
    playsinline
  >
    <source src="../../../../public/vid/hmt_sample.mp4" type="video/mp4" />
  </video>
</template>

<script setup></script>

<style scoped>
video {
  width: 100%;
  height: 250px;
}
.fit-image {
  max-width: 75px;
}

h6 {
  text-align: start !important;
}
</style>
