<template>
  <button
    type="button"
    data-bs-toggle="modal"
    data-bs-target="#restartModal"
    class="btn btn-outline-danger px-2 ms-2"
    :disabled="store.status !== 'in-progress'"
  >
    <font-awesome-icon icon="fa-rotate-right" class="px-1" /> Neu
  </button>
  <RestartModal />
</template>

<script setup>
import RestartModal from "@/components/utils/RestartModal.vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped></style>
